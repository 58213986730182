import { useEffect } from 'react'
import whatsAppImg from '../../assets/img/whatsapp.png'
import anuncioImg from '../../assets/img/anunciar.png'
import candidatoImg from '../../assets/img/candidato.png'
import gesttorImg from '../../assets/img/gesttor.png'
import cursosImg from  '../../assets/img/cursos.png'
import './links.css'

function Links () {

    useEffect (() => {
        document.title = 'Links | Hg'
    }, [])

    return (
        <main className='containerLinks'>
            <a href="https://wa.me/message/YYTHX6J2OKZ3O1" target="_blank" rel="noopener noreferrer"> 
                <section>
                    <span>
                        <h1>Fale Conosco</h1>
                        <p>Você será redirecionado para o <strong>WhatsApp</strong> para falar com o nosso time de atendimento</p>
                    </span>
                    <span>
                        <img src={whatsAppImg} alt='Atendimento do Hg' title='Atendimento do Hg'/>
                    </span>
                </section>
            </a>

            <br />

            <a href="https://www.hgconsultoria.co/vagas" target="_blank" rel="noopener noreferrer"> 
                <section>
                    <span>
                        <h1>Vagas de Emprego</h1>
                        <p>Acesse nosso portal de vagas e confira as <strong>oportunidades de emprego</strong> disponíveis no momento</p>
                    </span>
                    <span>
                        <img src={anuncioImg} alt='Vagas de Emprego' title='Vagas de Emprego'/>
                    </span>
                </section>
            </a>

            <a href="https://www.hgconsultoria.co/portal-auth#instagram-hg" target="_blank" rel="noopener noreferrer"> 
                <section>
                    <span>
                        <h1>Portal do Candidato</h1>
                        <p>Cadastre seu <strong>currículo</strong> em nossa plataforma e receba as melhores oportunidades de emprego com base em seu perfil e nas suas preferências.</p>
                    </span>
                    <span>
                        <img src={candidatoImg} alt='Cadastre seu Currículo' title='Portal do Candidato'/>
                    </span>
                </section>
            </a>

            <br />

            <a href="https://gesttor.co/novaOrganizacao" target="_blank" rel="noopener noreferrer"> 
                <section>
                    <span>
                        <h1>Sistema Gesttor</h1>
                        <p>Gerencie todas as suas tarefas e processos com agilidade e praticidade
                        Conheça o Sistema Gesttor, experimente <strong>grátis</strong> por 15 dias.</p>
                    </span>
                    <span>
                        <img src={gesttorImg} alt='Sistema Gesttor do Hg' title='Sistema Gesttor do Hg'/>
                    </span>
                </section>
            </a>

            <a href="udemy.com/user/hgcempresarialgmailcom" target="_blank" rel="noopener noreferrer"> 
                <section>
                    <span>
                        <h1>Cursos Gerencais</h1>
                        <p>Amplie seus conhecimentos para o mercado de trabalho. Confira os cursos e treinamentos que temos disponíveis e bons estudos.</p>
                    </span>
                    <span>
                        <img src={cursosImg} alt='Cursos Gerencais do Hg' title='Cursos Gerencais do Hg'/>
                    </span>
                </section>
            </a>
        </main>
        
    )
}

export default Links