import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LinksPage from "../../components/Links";

function Links () {

  return (
    <>
      <Header />
      <LinksPage />
      <Footer />
    </>
  )
}

export default Links;
